/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// INDEX /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------------ H --- */
$h: 'html.page_index';

/* --------------------------------------------------------------------- LIST --- */
#{$h} aside.list {
    display: none;
}
