/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// AGENT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------------ H --- */
$h: 'html.page_agent';

/* --------------------------------------------------------------------- NEWS --- */
#{$h} section.news article .content {
    display: none;
}
