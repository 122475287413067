/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// HEADER /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------- HEADER --- */
header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    pointer-events: none;
    @media (max-width: $break6) {
        align-items: stretch;
    }
}

/* --------------------------------------------------------------------- LOGO --- */
header a.logo {
    position: relative;
    z-index: 2;
    display: block;
    width: 552px;
    background-color: $snow6;
    mask-image: url('../gfx/logo.svg');
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    pointer-events: all;
    @media (max-width: $break1) {
        width: 460px;
    }
    @media (max-width: $break4) {
        width: 384px;
    }
    @media (max-width: $break5) {
        width: 288px;
    }
    @media (max-width: $break6) {
        margin-right: 24px;
    }
}
header a.logo::before {
    display: block;
    padding-top: calc(((26 / 259) * 100%));
    content: '';
}

/* ---------------------------------------------------------------------- NAV --- */
header nav {
    z-index: 1;
    display: flex;
    padding: inherit;
    align-items: center;
    flex-direction: row;
    @media (min-width: ($break6 + 1px)) {
        justify-content: space-between;
        @include position-fill(absolute);
    }
    @media (max-width: $break6) {
        padding: unset;
        flex-direction: column;
        @include position-center(fixed);
    }
}
header nav a {
    display: flex;
    width: 8em;
    height: calc(((8 / 3) * 1em));
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: $snow6;
    font-family: $family_main;
    text-transform: uppercase;
    border: 1px solid $snow6;
    border-radius: 50vw;
    transition: color 100ms, background-color 100ms, border-color 100ms;
    will-change: color, background-color, border-color;
    pointer-events: all;
    @include font-size(12px);
    @media (max-width: $break6) {
        @include font-size(15px);
    }
}
header nav a:not(:last-child) {
    @media (max-width: $break6) {
        margin-bottom: 1em;
    }
}
header nav a:hover {
    @media (pointer: fine) {
        color: white;
        background-color: $snow3;
        border-color: white;
    }
}
header nav a.active {
    color: $grey9;
    background-color: $snow6;
}

html:not(.nav_active) header nav {
    @media (max-width: $break6) {
        display: none;
    }
}

/* ------------------------------------------------------------------- TOGGLE --- */
header a.toggle {
    position: relative;
    z-index: 1;
    display: block;
    width: 30px;
    margin-left: auto;
    pointer-events: all;
    @media (min-width: ($break6 + 1px)) {
        display: none;
    }
}
header a.toggle div,
header a.toggle div::before,
header a.toggle div::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: white;
    border-radius: 50vw;
}
header a.toggle div {
    top: 50%;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    will-change: transform;
}
header a.toggle div::before,
header a.toggle div::after {
    display: block;
    content: '';
}
header a.toggle div::before {
    margin-top: calc(((50% - 2px) * -1));
    transition: margin-top 100ms 200ms ease, opacity 100ms ease;
    will-change: margin-top, opacity;
}
header a.toggle div::after {
    margin-top: calc((50% - 2px));
    transition: margin-top 100ms 200ms ease, transform 100ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    will-change: margin-top, transform;
}
html.nav_active header a.toggle div {
    transform: rotate(45deg);
    transition-delay: 200ms;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
html.nav_active header a.toggle div::before {
    margin-top: 0;
    transition: margin-top 100ms ease, opacity 100ms 200ms ease;
    opacity: 0;
}
html.nav_active header a.toggle div::after {
    margin-top: 0;
    transform: rotate(-90deg);
    transition: margin-top 100ms ease, transform 100ms 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
