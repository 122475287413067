/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// DIARY /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ SECTION --- */
section.diary {
    @extend %split;
}

/* --------------------------------------------------------------------- AREA --- */
section.diary .area {
    display: flex;
    padding: 56px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: $grey9;
    @media (min-width: ($break5 + 1px)) {
        position: fixed;
        top: 0;
        left: 0;
    }
    @media (max-width: $break1) {
        padding: 38px;
    }
    @media (max-width: $break4) {
        padding: 32px;
    }
    @media (max-width: $break5) {
        padding: 110px 26px 26px;
    }
    @media (max-width: $break6) {
        padding: 88px 20px 20px;
    }
}

/* ------------------------------------------------------------------ CONTENT --- */
section.diary .content {
    display: flex;
    padding: calc(((var(--vh, 1vh) * 50) - 56px)) 56px 152px;
    flex-direction: row;
    background-color: $grey6;
    @media (min-width: ($break5 + 1px)) {
        transform: translateX(100%);
    }
    @media (max-width: $break1) {
        padding: calc(((var(--vh, 1vh) * 50) - 38px)) 38px 108px;
    }
    @media (max-width: $break4) {
        padding: calc(((var(--vh, 1vh) * 50) - 32px)) 32px 92px;
    }
    @media (max-width: $break5) {
        padding: calc(((var(--vh, 1vh) * 50) - 26px)) 26px 80px;
    }
    @media (max-width: $break6) {
        padding: 20px 20px calc((20px + env(safe-area-inset-bottom)));
    }
    @media (max-width: $break6) {
        flex-direction: column;
    }
}
section.diary .content > * {
    @media (min-width: ($break6 + 1px)) {
        width: 50%;
        border-top: 1px solid $grey3;
    }
}

/* ----------------------------------------------------------------- METADATA --- */
section.diary .metadata {
    font-family: $family_body;
    line-height: 1.6;
    @include font-size(12px);
    @media (min-width: ($break6 + 1px)) {
        padding-top: 0.5em;
    }
    @media (max-width: $break6) {
        margin-bottom: 4em;
    }
}
section.diary .metadata time {
    color: $grey3;
}
section.diary .metadata .count {
    color: $grey0;
}
section.diary .metadata .count:empty {
    display: none;
}
section.diary .metadata .count::after {
    margin-left: 0.625em;
    content: 'artists \A available';
    white-space: pre-wrap;
}
section.diary .metadata .count.singular::after {
    content: 'artist \A available';
}

/* ------------------------------------------------------------------- ARTIST --- */
section.diary .artist {
    @include font-size(24px);
    @media (min-width: ($break6 + 1px)) {
        padding-top: 0.25em;
    }
}
section.diary .artist:empty::before {
    display: block;
    color: $grey0;
    content: 'Loading artists...';
    font-family: $family_body;
    line-height: 1.6;
    @include font-size(12px);
}
section.diary .artist a.name {
    display: block;
    color: $snow6;
    font-family: $family_main;
    text-transform: uppercase;
}
section.diary .artist a.name:not(:last-child) {
    padding-bottom: 0.25em;
    margin-bottom: 0.25em;
    border-bottom: 1px solid $grey3;
}
