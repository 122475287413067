/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// MIXIN /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------- FONT : SIZE --- */
@mixin font-size($size) {
    font-size: round(($size * pow(1.2, 2)));
    @media (max-width: $break1) {
        font-size: round(($size * 1.2));
    }
    @media (max-width: $break4) {
        font-size: $size;
    }
    @media (max-width: $break6) {
        @if ($size > 15) {
            font-size: round(($size * 1.1));
        }
        @else {
            font-size: $size;
        }
    }
    @media (max-width: $break7) {
        @if ($size > 25) {
            font-size: round(($size * pow(0.8, 2)));
        }
    }
    @media (max-width: $break8) {
        font-size: $size;
    }
}

/* -------------------------------------------------------- POSITION : CENTER --- */
@mixin position-center($position) {
    position: $position;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* ---------------------------------------------------------- POSITION : FILL --- */
@mixin position-fill($position) {
    position: $position;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////// FUNCTION /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- POW --- */
@function pow($base, $exponent) {
    $value: 1;
    @for $a from 1 through $exponent {
        $value: ($value * $base);
    }
    @return $value;
}
