/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// ROSTER /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ SECTION --- */
section.roster {
    @extend %block;
}

/* ------------------------------------------------------------------- ANCHOR --- */
section.roster a {
    z-index: 3;
    @include position-fill(absolute);
}

/* ------------------------------------------------------------------ ARTICLE --- */
section.roster article {
    position: relative;
}

/* ------------------------------------------------------------------ PICTURE --- */
section.roster .picture {
    z-index: 1;
    transition: opacity 200ms;
    will-change: opacity;
    @media (pointer: fine) {
        opacity: 0.25;
    }
}
section.roster article:hover .picture {
    @media (pointer: fine) {
        opacity: 1;
    }
}
section.roster .picture::before {
    display: block;
    padding-top: 150%;
    content: '';
}

/* --------------------------------------------------------------------- NAME --- */
section.roster .name {
    pointer-events: none;
}
section.roster .name::before,
section.roster .name::after {
    position: absolute;
    z-index: 2;
    display: block;
    content: attr(data-artist-name);
    font-family: $family_main;
    text-align: center;
    text-transform: uppercase;
    transition: opacity 200ms;
    will-change: opacity;
}
section.roster .name::before {
    bottom: 0;
    left: 0;
    width: 100%;
    margin: calc(((16 / 9) * 1em)) 0;
    color: $snow6;
    @include font-size(18px);
    @media (pointer: fine) {
        opacity: 0.25;
    }
}
section.roster article:hover .name::before {
    @media (pointer: fine) {
        opacity: 0;
    }
}
section.roster .name::after {
    color: $snow6;
    word-spacing: 100vw;
    opacity: 0;
    @include font-size(64px);
    @include position-center(fixed);
}
section.roster article:hover .name::after {
    @media (pointer: fine) {
        opacity: 1;
    }
}
