/* ////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// PICTURE /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ PICTURE --- */
.picture {
    position: relative;
    overflow: hidden;
    background-image: url('../gfx/tmp.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.picture::after {
    z-index: 1;
    display: block;
    margin: -1px;
    content: '';
    background-color: rgba($grey6, 0.8);
    @include position-fill(absolute);
}
