/* ////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// VAR /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- COLOR --- */
/* grey */
$grey9: #272523;
$grey6: #2b2927;
$grey3: #504c4b;
$grey0: #898786;

/* snow */
$snow6: rgba(white, 0.85);
$snow3: rgba(white, 0.25);

/* ------------------------------------------------------------------- FAMILY --- */
$family_main: ('typ1451', monospace);
$family_body: ('maru', sans-serif);

/* -------------------------------------------------------------------- BREAK --- */
$break0: 2000px;
$break1: 1920px;
$break2: 1600px;
$break3: 1440px;
$break4: 1280px;
$break5: 800px;
$break6: 570px;
$break7: 416px;
$break8: 330px;
