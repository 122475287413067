/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// MASK /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- MASK --- */
.mask {
    z-index: 2;
    pointer-events: none;
    @include position-fill(fixed);
    @media (max-width: $break6) {
        display: none;
    }
}
.mask::before,
.mask::after {
    position: absolute;
    left: 0;
    display: block;
    width: 100vw;
    content: '';
}
.mask::before {
    top: 0;
    height: 168px;
    background-image: linear-gradient(180deg, rgba($grey9, 1) 0%, rgba($grey9, 0) 100%);
    @media (max-width: $break1) {
        height: 128px;
    }
    @media (max-width: $break4) {
        height: 104px;
    }
    @media (max-width: $break5) {
        height: 84px;
    }
}
.mask::after {
    bottom: 0;
    height: 152px;
    background-image: linear-gradient(0deg, rgba($grey9, 1) 0%, rgba($grey9, 0) 100%);
    @media (max-width: $break1) {
        height: 108px;
    }
    @media (max-width: $break4) {
        height: 92px;
    }
    @media (max-width: $break5) {
        height: 80px;
    }
}
