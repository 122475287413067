/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// FONT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ TYP1451 --- */
@font-face {
    font-family: 'typ1451';
    font-style: normal;
    font-weight: normal;
    src: url('../font/typ1451.woff2') format('woff2'), url('../font/typ1451.woff') format('woff');
}

/* --------------------------------------------------------------------- MARU --- */
@font-face {
    font-family: 'maru';
    font-style: normal;
    font-weight: normal;
    src: url('../font/maru.woff2') format('woff2'), url('../font/maru.woff') format('woff');
}
