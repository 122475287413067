/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// LIST /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- ASIDE --- */
aside.list {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba($grey3, 0.6);
    @include position-fill(absolute);
    @media (max-width: $break5) {
        padding-top: 52px;
    }
    @media (max-width: $break6) {
        padding-top: 42px;
    }
}
aside.list div {
    display: flex;
    max-width: 50em;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    @include font-size(18px);
    @media (min-width: ($break6 + 1px)) {
        width: 100%;
        margin: -0.5em;
        flex-wrap: wrap;
    }
    @media (max-width: $break6) {
        flex-direction: column;
    }
}

/* ------------------------------------------------------------------- ANCHOR --- */
aside.list a {
    display: flex;
    height: calc(((16 / 9) * 1em));
    padding: 0 1em;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: $snow6;
    font-family: $family_main;
    text-transform: uppercase;
    white-space: nowrap;
    border: 1px solid $snow6;
    border-radius: 50vw;
    transition: color 100ms, background-color 100ms, border-color 100ms;
    will-change: color, background-color, border-color;
    @media (min-width: ($break6 + 1px)) {
        margin: 0.5em;
    }
    @media (max-width: $break6) {
        width: 100%;
    }
}
aside.list a:not(:last-child) {
    @media (max-width: $break6) {
        margin-bottom: 1em;
    }
}
aside.list a:hover {
    @media (pointer: fine) {
        color: white;
        background-color: $snow3;
        border-color: white;
    }
}
