/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////// CALENDAR /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------- CALENDAR --- */
.calendar {
    display: flex;
    flex-direction: column;
    font-family: $family_main;
    @include font-size(18px);
    @media (max-width: $break7) {
        width: 100%;
    }
}

/* ---------------------------------------------------------------------- ROW --- */
.calendar .row {
    display: flex;
    flex-direction: row;
    @media (max-width: $break7) {
        justify-content: space-between;
    }
}
.calendar .row:not(:last-child) {
    margin-bottom: calc(((8 / 9) * 1em));
}
.calendar .row.action {
    align-items: center;
    justify-content: space-between;
}

/* --------------------------------------------------------------------- NAME --- */
.calendar a.name {
    color: $snow6;
    text-transform: uppercase;
}

/* ------------------------------------------------------------------ CONTROL --- */
.calendar a.control {
    position: relative;
    display: block;
    width: calc(((16 / 9) * 1em));
}
.calendar a.control.disabled {
    pointer-events: none;
}
.calendar a.control::before {
    display: block;
    padding-top: 100%;
    content: '';
}
.calendar a.control::after {
    display: block;
    width: calc(((5 / 9) * 1em));
    height: calc(((5 / 9) * 1em));
    content: '';
    background-color: $snow6;
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    transition: color 200ms;
    will-change: color;
    @include position-center(absolute);
}
.calendar a.control.disabled::after {
    background-color: $grey3;
}
.calendar a:hover.control::after {
    @media (pointer: fine) {
        color: white;
    }
}
.calendar a.control.prev::after {
    mask-image: url('../gfx/calendar/prev.svg');
}
.calendar a.control.next::after {
    mask-image: url('../gfx/calendar/next.svg');
}

/* --------------------------------------------------------------------- CELL --- */
.calendar .cell {
    display: flex;
    width: calc(((16 / 9) * 1em));
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.calendar .cell:not(:last-child) {
    @media (min-width: ($break7 + 1px)) {
        margin-right: calc(((8 / 9) * 1em));
    }
}
.calendar div.cell {
    color: $snow6;
}
.calendar a.cell {
    height: calc(((16 / 9) * 1em));
    color: $grey3;
    border: 1px solid $grey3;
    border-radius: 50%;
    transition: color 100ms, border-color 100ms;
    will-change: color, border-color;
}
.calendar a.cell:hover {
    @media (pointer: fine) {
        color: $grey0;
        border-color: $grey0;
    }
}
.calendar a.cell.active {
    color: $snow6;
    border-color: $snow6;
}
