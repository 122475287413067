/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// ERROR /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ SECTION --- */
section.error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    flex-direction: column;
}

/* ------------------------------------------------------------------ HEADING --- */
section.error h1 {
    margin-bottom: 1em;
    color: $snow6;
    font-family: $family_main;
    text-align: center;
    text-transform: uppercase;
    @include font-size(64px);
}

/* ------------------------------------------------------------------- BUTTON --- */
section.error a {
    display: flex;
    height: calc(((8 / 3) * 1em));
    padding: 0 1em;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: $snow6;
    font-family: $family_main;
    text-transform: uppercase;
    border: 1px solid $snow6;
    border-radius: 50vw;
    transition: color 100ms, border-color 100ms;
    will-change: color, border-color;
    @include font-size(12px);
}
section.error a:hover {
    @media (pointer: fine) {
        color: white;
        border-color: white;
    }
}
