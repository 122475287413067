/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// BLOCK /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- BLOCK --- */
%block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
%block > * {
    width: calc(((1 / 6) * 100%));
    @media (max-width: $break0) {
        width: 20%;
    }
    @media (max-width: $break2) {
        width: 25%;
    }
    @media (max-width: $break5) {
        width: calc(((1 / 3) * 100%));
    }
    @media (max-width: $break6) {
        width: 50%;
    }
    @media (max-width: $break8) {
        width: 100%;
    }
}
