/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// ARTIST /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ SECTION --- */
section.artist {
    @extend %split;
}

/* ------------------------------------------------------------------ PICTURE --- */
section.artist .picture {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    @media (min-width: ($break5 + 1px)) {
        position: fixed;
        top: 0;
        left: 0;
    }
    @media (max-width: $break5) {
        min-height: calc((var(--vh, 1vh) * 75));
    }
}
section.artist .picture::before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 25%;
    background-image: linear-gradient(0deg, rgba($grey6, 1) 0%, rgba($grey6, 0) 50%);
    @media (max-width: $break5) {
        content: '';
    }
}
section.artist .picture .name {
    position: relative;
    z-index: 2;
    color: $snow6;
    font-family: $family_main;
    text-align: center;
    text-transform: uppercase;
    word-spacing: 100vw;
    @include font-size(64px);
}

/* ------------------------------------------------------------------ CONTENT --- */
section.artist .content {
    padding: calc(((var(--vh, 1vh) * 50) - 56px)) 56px 152px;
    align-content: flex-start;
    @media (min-width: ($break5 + 1px)) {
        transform: translateX(100%);
    }
    @media (max-width: $break1) {
        padding: calc(((var(--vh, 1vh) * 50) - 38px)) 38px 108px;
    }
    @media (max-width: $break4) {
        padding: calc(((var(--vh, 1vh) * 50) - 32px)) 32px 92px;
    }
    @media (max-width: $break5) {
        padding: 26px 26px 80px;
    }
    @media (min-width: ($break4 + 1px)), (max-width: $break5) and (min-width: ($break6 + 1px)) {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(2, auto);
    }
    @media (max-width: $break4) and (min-width: ($break5 + 1px)), (max-width: $break6) {
        display: flex;
        flex-direction: column;
    }
    @media (max-width: $break6) {
        padding: 20px 20px calc((20px + env(safe-area-inset-bottom)));
    }
}
section.artist .content::before {
    margin-bottom: 1.75em;
    color: $snow6;
    content: 'About';
    font-family: $family_main;
    text-transform: uppercase;
    @include font-size(64px);
    @media (min-width: ($break4 + 1px)), (max-width: $break5) and (min-width: ($break6 + 1px)) {
        grid-column: 1 / -1;
        grid-row: 1;
        text-align: center;
    }
    @media (max-width: $break4) and (min-width: ($break5 + 1px)), (max-width: $break6) {
        margin-bottom: 0.75em;
    }
}

/* -------------------------------------------------------------------- AGENT --- */
section.artist .agent {
    display: flex;
    width: 192px;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: column;
    grid-column: 1;
    grid-row: 2;
    font-family: $family_body;
    line-height: 1.6;
    @include font-size(12px);
    @media (max-width: $break4) and (min-width: ($break5 + 1px)), (max-width: $break6) {
        margin-bottom: 3em;
    }
}
section.artist .agent::before {
    display: block;
    color: $grey3;
    content: 'Represented by';
}
section.artist .agent a {
    display: block;
    color: $grey0;
    transition: color 100ms;
    will-change: color;
}
section.artist .agent a:hover {
    @media (pointer: fine) {
        color: white;
    }
}
section.artist .agent a.email {
    margin-top: 1em;
}

/* --------------------------------------------------------------------- TEXT --- */
section.artist .text {
    margin-bottom: 4em;
    grid-column: 2;
    grid-row: 2;
    color: $grey0;
    font-family: $family_body;
    line-height: 1.6;
    @include font-size(12px);
}
section.artist .text p:not(:last-child) {
    margin-bottom: 1em;
}

/* ------------------------------------------------------------------- ACTION --- */
section.artist .action {
    display: flex;
    margin: -1em -0.5em 0;
    flex-direction: row;
    flex-wrap: wrap;
    grid-column: 2;
    grid-row: 3;
    @include font-size(12px);
}
section.artist .action a {
    display: flex;
    width: calc((((1 / 3) * 100%) - 1em));
    height: calc(((8 / 3) * 1em));
    padding: 0 1em;
    margin: 1em 0.5em 0;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 1px solid $snow6;
    border-radius: 50vw;
    transition: color 200ms, background-color 100ms, border-color 100ms;
    will-change: color, background-color, border-color;
    @media (max-width: $break6) {
        width: calc((50% - 1em));
    }
}
section.artist .action a:hover {
    @media (pointer: fine) {
        color: white;
        background-color: $snow3;
        border-color: white;
    }
}
section.artist .action span {
    display: block;
    overflow: hidden;
    color: $snow6;
    font-family: $family_main;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
}
