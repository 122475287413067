/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// MAIN /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- MAIN --- */
main {
    position: relative;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
}
