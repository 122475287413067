/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// FOOTER /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------- FOOTER --- */
footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    pointer-events: none;
    @media (max-width: $break6) {
        justify-content: center;
    }
}

/* ---------------------------------------------------------------------- NAV --- */
footer nav {
    font-family: $family_main;
    text-transform: uppercase;
    @include font-size(12px);
    @media (min-width: ($break6 + 1px)) {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
    }
    @media (max-width: $break6) {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
        text-align: center;
        @include font-size(15px);
    }
}
footer nav::before {
    margin-bottom: 0.25em;
    color: $grey0;
    content: 'Login';
    @media (min-width: ($break6 + 1px)) {
        grid-column: 1 / -1;
    }
    @media (max-width: $break6) {
        width: 100%;
        padding-bottom: 0.75em;
        margin-bottom: 0.75em;
        border-bottom: 1px solid rgba(white, 0.15);
    }
}
footer nav a {
    display: block;
    color: $snow6;
    pointer-events: all;
    @media (max-width: $break6) {
        width: 100%;
    }
}
footer nav a:not(:last-child) {
    @media (min-width: ($break6 + 1px)) {
        position: relative;
        padding-right: calc((1em + 1px));
        margin-right: 1em;
    }
    @media (max-width: $break6) {
        padding-bottom: 0.75em;
        margin-bottom: 0.75em;
        border-bottom: 1px solid rgba(white, 0.15);
    }
}
footer nav a:not(:last-child)::after {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 1px;
    height: 1em;
    background-color: $grey0;
    transform: translateY(-50%);
    @media (min-width: ($break6 + 1px)) {
        content: '';
    }
}

html:not(.nav_active) footer nav {
    @media (max-width: $break6) {
        display: none;
    }
}
