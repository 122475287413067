/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// NEWS /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ SECTION --- */
section.news {
    display: flex;
    width: 100%;
    height: auto;
    min-height: calc((var(--vh, 1vh) * 100));
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: $break5) {
        flex-direction: column;
    }
}

/* ------------------------------------------------------------------ ARTICLE --- */
section.news article {
    position: relative;
    display: flex;
    width: 50vw;
    padding: 2.5%;
    height: auto;
    min-height: calc((var(--vh, 1vh) * 50));
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: $break5) {
        width: 100vw;
    }
}

/* ------------------------------------------------------------------- ANCHOR --- */
section.news a {
    z-index: 3;
    @include position-fill(absolute);
}

/* ------------------------------------------------------------------ PICTURE --- */
section.news .picture {
    z-index: 1;
    @include position-fill(absolute);
    @media (max-width: $break6) {
        overflow: visible;
    }
}
section.news article:nth-child(odd) .picture::after {
    @media (max-width: $break6) {
        background-image: linear-gradient(0deg, rgba($grey6, 1) 0%, rgba($grey6, 0) 50%);
    }
}
section.news article:nth-child(even) .picture::after {
    @media (max-width: $break6) {
        background-image: linear-gradient(180deg, rgba($grey6, 1) 0%, rgba($grey6, 0) 50%);
    }
}

/* ------------------------------------------------------------------ CONTENT --- */
section.news .content {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: $family_main;
    line-height: 1.125;
    text-transform: uppercase;
    @include font-size(24px);
}
section.news .content::after {
    display: block;
    width: calc(((2 / 3) * 1em));
    height: calc(((2 / 3) * 1em));
    content: '';
    background-color: $grey0;
    mask-image: url('../gfx/arrow.svg');
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
}
section.news .content time {
    color: $grey0;
}
section.news .content h2 {
    margin-bottom: 0.125em;
    color: $snow6;
    text-align: center;
}
