/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// SPLIT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- SPLIT --- */
%split {
    display: flex;
    width: 100%;
    height: auto;
    min-height: calc((var(--vh, 1vh) * 100));
    flex-direction: row;
    @media (max-width: $break5) {
        flex-direction: column;
    }
}
%split > * {
    width: 50vw;
    height: auto;
    min-height: calc((var(--vh, 1vh) * 100));
    @media (max-width: $break5) {
        width: 100vw;
        min-height: calc((var(--vh, 1vh) * 50));
    }
}
