/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////// TYPOGRAPHY /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------- DOCUMENT --- */
html,
body {
    color: red;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* ------------------------------------------------------------------ HEADING --- */

/* ---------------------------------------------------------------- PARAGRAPH --- */

/* ------------------------------------------------------------------- ANCHOR --- */
a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
