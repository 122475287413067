/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////// DOCUMENT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------- DOCUMENT --- */
html,
body {
    user-select: none;
}

/* --------------------------------------------------------------------- HTML --- */
html {
    height: 100%;
    background-color: $grey6;
}

html.nav_active {
    overflow: hidden;
}

/* --------------------------------------------------------------------- BODY --- */
body {
    display: flex;
    min-height: 100%;
    flex-direction: column;
}
body::before {
    z-index: 4;
    display: block;
    background-color: rgba($grey3, 0.95);
    transition: opacity 300ms;
    will-change: opacity;
    opacity: 0;
    pointer-events: none;
    @include position-fill(fixed);
    @media (max-width: $break6) {
        content: '';
    }
}

html.nav_active body {
    overflow: hidden;
}
html.nav_active body::before {
    opacity: 1;
}

/* ------------------------------------------------------------------- LAYOUT --- */
header,
aside,
footer {
    padding: 56px;
    @media (max-width: $break1) {
        padding: 38px;
    }
    @media (max-width: $break4) {
        padding: 32px;
    }
    @media (max-width: $break5) {
        padding: 26px;
    }
    @media (max-width: $break6) {
        padding: 20px;
    }
}
